import { Modal, Box, Typography, Button, styled } from '@mui/material';
import { t } from 'i18next';
import React from 'react';
import theme from '../theme';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {xs:'95%' ,lg:'35%'},
    maxHeight: {xs:'60%' ,md:'80%'},
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflowY: 'auto',
    //border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: '25px',
    scrollbarWidth: 'none', 
    msOverflowStyle: 'none',
};


const StyledModal = styled(Modal)({
    '& .MuiPaper-root': {
      outline: 'none',  
      boxShadow: 'none',
      borderRadius: '25px',
    },
    '& .MuiModal-root': {
      outline: 'none',  
      borderRadius: '25px',
    },
    '& *:focus': {
      outline: 'none',  
    },
    borderRadius: '25px',
  });
  

function Conditions({storeDetails, conditionOpen, setConditionOpen, handleConditionOpen, handleConditionClose }) {
    console.log("openedd",conditionOpen);
    
    
    return (
        <div >
            <StyledModal
                open={conditionOpen}
                onClick={(event, reason) => {
                    if (reason !== "backdropClick") {
                        handleConditionClose();
                    }
                    console.log("closed");
                    
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                
            >
                <Box sx={style} onClick={() => handleConditionOpen} >
                    <Box sx={{ flexGrow: 1, overflowY: 'auto', mb: 4 }}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                          Samtykke til behandling af personoplysninger hos <strong> {storeDetails?.name}</strong><br/>Behandling af dine personoplysninger – samtykke
                        </Typography>
                        <Typography  whiteSpace="pre-line"  sx={{ mt: 2 }}>
                        Jeg giver herved mit samtykke til, at {storeDetails?.name} må indsamle og behandle mine personoplysninger. Disse personoplysninger omfatter:
                        <br/><br/>
                        <ul  style={{ listStyleType: 'disc', paddingLeft: '30px' }}>
                            <li>Navn</li>
                            <li>E-mailadresse</li>
                            <li>Telefonnummer</li>
                            <li>Adresse</li>
                            <li>Kontaktoplysninger</li>
                            <li>CPR-nummer</li>
                            <li>Helbredsoplysninger, der kan være relevante for min behandling</li>
                            <li>Journalføring, herunder filer (f.eks. billeder af det behandlede område), hvis dette er nødvendigt</li>
                        </ul>
                        <br/>
                        De indsamlede oplysninger opbevares elektronisk hos Fiind (www.fiind.app).
                        <br/>
                        <br/>
                        Dette samtykke gives i overensstemmelse med gældende lovgivning.
                        <br/><br/>
                        <strong>Tilbagetrækning af samtykke</strong>
                        <br/><br/>
                        Du har til enhver tid ret til at trække dit samtykke tilbage. Dette kan gøres ved at kontakte {storeDetails?.name} via e-mail: {storeDetails?.email}
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            position: 'sticky',
                            bottom: -32,
                            bgcolor: 'background.paper',
                            paddingTop:'10px',
                            paddingBottom:'10px',
                            
                        }}
                    >
                        {/* <Button
                            variant="contained"
                            color="primary"
                            onClick={handleConditionClose}
                            sx={{color:'white', textTransform:'initial', width:{xs:'100%' , md:'auto'} , borderRadius:{xs:'10px' , md:''}}}
                        >
                           {t('iAcceptPolicies')}
                        </Button> */}
                    </Box>
                </Box>
            </StyledModal>
        </div>
    );
}

export default Conditions;
