import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import {
    ListItem, useTheme, useMediaQuery, Box, Modal, Skeleton, Autocomplete, Divider, Button, MenuItem, InputLabel, List, CircularProgress, ListItemIcon,
    Container, Typography, TextField, Grid, Chip, Radio, RadioGroup, FormControlLabel, Paper, Select, FormControl, Card, CardContent, InputAdornment, ListItemText,
    IconButton
} from '@mui/material';
import theme from '../theme';
import { t } from 'i18next';

import CloseIconModal from '../assets/images/crossIcon.png';

function AdvanceFilter(props) {

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isMdScreen = useMediaQuery(theme.breakpoints.up('md'));
    const apiKey = 'AIzaSyCsQItPVAfOTEJovaelQ3WFaVmuxoOG3kI';

    const { open, handleClose, categoryList, selectedCategoryData, selectedData, search } = props

    // console.log('CATEGORY DATA ', JSON.stringify(selectedCategoryData))

    const [selectedServiceIds, setSelectedServiceIds] = useState([]);

    const [kilometer, setKilometer] = React.useState(selectedData?.kilometer);


    // const [isLeftBoxVisible, setIsLeftBoxVisible] = useState(true);

    const [categories, setCategories] = useState(categoryList);

    // const [selectedCategory, setSelectedCategory] = useState(selectedCategoryData);
    const [selectedCategory, setSelectedCategory] = useState(selectedCategoryData || {});
    // console.log('SELCTED ___ CATEGORY ', JSON.stringify(selectedCategory))

    const [serviceTypes, setServiceTypes] = useState([]);

    const [selectedFirstRow, setSelectedFirstRow] = useState('Standard');
    // console.log('SELECTED FIRST ROW_____________- ', selectedFirstRow)

    const [selectedSecondRow, setSelectedSecondRow] = useState(null);

    const [fromValue, setFromValue] = useState(selectedData?.fromValue);

    const [toValue, setToValue] = useState(selectedData?.toValue);

    const maxRows = 3;
    const itemsPerRow = 3;
    const maxVisibleItems = maxRows * itemsPerRow;

    // const sortedServices = [...serviceTypes].sort((a, b) => {
    //     const aSelected = selectedServiceIds.some(service => service.id === a.id);
    //     const bSelected = selectedServiceIds.some(service => service.id === b.id);
    //     return (bSelected - aSelected);
    // });
    const sortedServices = (Array.isArray(serviceTypes) ? [...serviceTypes] : []).sort((a, b) => {
        const aSelected = selectedServiceIds.some(service => service.id === a.id);
        const bSelected = selectedServiceIds.some(service => service.id === b.id);
        return (bSelected - aSelected);
    });

    const visibleServices = sortedServices.slice(0, maxVisibleItems);
    const hiddenCount = sortedServices.length - maxVisibleItems;

    const cosmeticsData = [
        { id: 1, name: 'Standard' },
        { id: 2, name: 'Cancellation offers' },
        { id: 3, name: 'Special offers' },
        { id: 4, name: 'Offer to new clients' },
        { id: 5, name: 'Consulation' },
        { id: 6, name: 'Bundle offers' },
    ];

    useEffect(() => {
        // const {selectedData} = props
        setSelectedCategory(selectedCategory)
        console.log('selectedData123', selectedData);
        setSelectedServiceIds(selectedData?.selectedServiceIds ?? [])
        setKilometer(selectedData?.kilometer)
        setSelectedFirstRow(selectedData?.selectedFirstRow)
        setSelectedSecondRow(selectedData?.selectedSecondRow ?? null)
        setFromValue(selectedData?.fromValue)
        setToValue(selectedData?.toValue)
    }, [open])


    const handleChange = (event) => {
        setKilometer(event.target.value);
    };

    const handleSelectionChange = (event, newValue) => {
        setSelectedServiceIds(newValue);
    };

    useEffect(() => {
        if (Array.isArray(selectedCategory)) {
            const allServiceTypes = selectedCategory.flatMap(category => category.service_types);
            // console.log('Total count of service types (Allselect): ', allServiceTypes.length);
            setServiceTypes(allServiceTypes);
        } else if (selectedCategory && selectedCategory.service_types) {
            // console.log('Total count of service types (Singleselect): ', selectedCategory.service_types.length);
            setServiceTypes(selectedCategory.service_types);
        } else {
            setServiceTypes([]);
        }
    }, [selectedCategory]);

    // useEffect(() => {
    //     // setSelectedServiceIds([])

    //     if (selectedCategory) {
    //         setServiceTypes(selectedCategory?.service_types)
    //     }
    // }, [selectedCategory]);

    const handleBoxClick = (item) => {
        if (selectedCategory && selectedCategory.id === item.id) {
            setSelectedCategory(null);
            setSelectedServiceIds([])
            // resetFunction();
        } else {
            setSelectedCategory(item);
            setSelectedServiceIds([])
            // setSelectedOptionAlongWithCategory(null);
            // resetFunction();
        }
    };
    const handleFirstRowClick = (name) => {
        setSelectedFirstRow(name);
    };
    const handleServiceClick = (item) => {
        if (selectedServiceIds.some(service => service.id === item.id)) {
            setSelectedServiceIds(selectedServiceIds.filter(service => service.id !== item.id));
        } else {
            setSelectedServiceIds([...selectedServiceIds, item]);
        }
    };

    const handleNumericInput = (event, setter) => {
        const value = event.target.value;
        const regex = /^(?!\.)\d*\.?\d*$/;

        if (regex.test(value) || value === '') {
            setter(value);
        } else {
            event.target.value = value.replace(/[^0-9.]/g, '').replace(/^(?!\.)\./, '').replace(/(\..*)\..*/g, '$1');
        }
    };

    const handleSecondRowClick = (name) => {
        if (name == selectedSecondRow) {
            setSelectedSecondRow(null)
        } else {
            setSelectedSecondRow(name);
        }
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isSmallScreen ? 350 : 500,
        bgcolor: 'background.paper',
        border: `3px solid ${theme.palette.primary.main}`,
        borderRadius: 3,
        boxShadow: 0,
        p: isSmallScreen ? 2 : 4,
        pt: 0
    };

    console.log('selectedFirstrow', selectedFirstRow);


    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={[style, {
                zIndex: 12,
                justifyContent: 'center', alignItems: 'center',
                overflowY: { xs: 'auto', sm: '85vh' },
                overflowX: 'hidden',
                maxHeight: { xs: '90vh', sm: '85vh' },
                position: 'relative'
            }]}>

                {isSmallScreen &&
                    <img
                        src={CloseIconModal}
                        alt="IconOne"
                        style={{ position: 'absolute', top: 16, left: 16, width: 15, height: 15 }}
                        onClick={handleClose}
                    />
                }

                <Typography variant="h5" textAlign="center" sx={{ paddingTop: 2, color: '#6f6f6f', fontWeight: 500, fontSize: '1.25rem' }}>
                    {t('Extendedsearch')}
                </Typography>

                <Typography id="modal-modal-description" sx={{ mt: 1, fontWeight: "500", color: '#1f1f1f' }} textAlign="center">
                    {t('Filtercategories')}
                </Typography>

                <Grid
                    container
                    spacing={isSmallScreen ? 0 : 1}
                    marginTop={2}

                    sx={{ justifyContent: 'flex-start', alignItems: 'flex-start', pr: isSmallScreen ? 0 : 9, pl: isSmallScreen ? 0 : 9 }}
                >

                    {categories.slice(0, 3).map((item, index) => (
                        <Grid key={index} item lg={4} md={4} sm={4} xs={4}>
                            <Box display="flex" flexDirection="column" alignItems="center" onClick={() => handleBoxClick(item)}>
                                <Paper
                                    elevation={6}
                                    sx={(theme) => ({
                                        height: isSmallScreen ? 50 : 55,
                                        width: isSmallScreen ? 50 : 55,
                                        borderRadius: 3,
                                        backgroundColor: '#fff',
                                        cursor: 'pointer', backgroundColor: 'white',
                                        position: 'relative', justifyContent: 'center',
                                        alignItems: 'center',
                                        border: selectedCategory && selectedCategory.id === item.id ? `2px solid ${theme.palette.primary.main}` : 'none',
                                        ...theme.applyStyles('dark', {
                                            backgroundColor: '#1A2027',
                                        }),
                                    })}>

                                    <img
                                        src={`https://fiind-dev.s3.amazonaws.com/${item.image}`}
                                        alt="IconOne"
                                        style={{
                                            width: '60%',
                                            height: '60%',
                                            objectFit: 'contain',
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)'
                                        }}
                                    />
                                </Paper>

                                <Typography
                                    variant="body1"
                                    color="textSecondary"
                                    textAlign="center"
                                    mt={1.5}
                                    sx={{
                                        fontSize: '0.75rem',
                                        minHeight: '20px',
                                        maxWidth: '80%',
                                        lineHeight: '1.2',
                                        color: '#1f1f1f'
                                    }}
                                >
                                    {item.category}
                                </Typography>
                            </Box>
                        </Grid>
                    ))}
                </Grid>

                <Typography id="modal-modal-description" variant='body2' sx={{ mt: 1, ml: isSmallScreen ? 0 : 2, fontWeight: "500", color: '#1f1f1f', fontSize: '1rem' }} textAlign="left">
                    {t("search")} {selectedServiceIds.length !== 0 && `(${selectedServiceIds.length} ${t("selected")})`}
                </Typography>

                <Box sx={{ ml: isSmallScreen ? 0 : 2, mt: 1, width: isSmallScreen ? '100%' : '98%' }}>
                    <Autocomplete
                        multiple
                        limitTags={9}
                        id="service-selection-demo"
                        options={serviceTypes || []}
                        disabled={!selectedCategory}
                        value={selectedServiceIds}
                        onChange={handleSelectionChange}
                        getOptionLabel={(option) => option.service_type}
                        sx={{
                            borderRadius: '10px',
                            border: `2px solid ${theme.palette.primary.main}`,
                            '& .MuiSelect-select': {
                                padding: '8px',
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: 'none',
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: `2px solid ${theme.palette.primary.main}`,
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: `2px solid ${theme.palette.primary.main}`,
                            },
                            // '& .MuiOutlinedInput-root': {
                            //     height: '40px',
                            // }
                        }}
                        renderOption={(props, option, { selected }) => (
                            <>
                                <div
                                    {...props}
                                    style={{
                                        backgroundColor: 'transparent',
                                        borderBottom: (serviceTypes.indexOf(option) < serviceTypes.length - 1) && `2px solid ${theme.palette.primary.main}`,
                                        '&:hover': { backgroundColor: 'transparent' },
                                        '&:focus': { backgroundColor: 'transparent' },
                                    }}
                                >
                                    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', height: 27 }}>
                                        <Typography variant="body2" style={{ margin: 0, color: '#6F6F6F' }}>
                                            {option.service_type}
                                        </Typography>
                                        <Radio
                                            checked={selected}
                                            size="small"
                                            sx={{
                                                padding: 0,
                                                color: theme.palette.primary.main,
                                                '&.Mui-checked': {
                                                    color: theme.palette.primary.main,
                                                },
                                            }}
                                            onChange={() => {
                                                if (selected) {
                                                    setSelectedServiceIds(selectedServiceIds.filter(service => service.id !== option.id));
                                                } else {
                                                    setSelectedServiceIds([...selectedServiceIds, option]);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                        style={{ minWidth: 205 }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder={t('Searchforservice')}
                                sx={{ color: '#6F6F6F' }}
                            />
                        )}
                        renderTags={(service_types, getTagProps) => {
                            const displayedTags = service_types.slice(0, 9);
                            const extraTags = service_types.length - 9;

                            return (
                                <>
                                    {displayedTags.map((option, index) => (
                                        <Chip
                                            key={option.id}
                                            label={option.service_type}
                                            {...getTagProps({ index })}
                                            sx={{
                                                margin: '2px',
                                                backgroundColor: theme.palette.primary.main,
                                                color: '#fff',
                                                '& .MuiChip-deleteIcon': {
                                                    color: '#fff',
                                                },
                                            }}
                                        />
                                    ))}

                                    {extraTags > 0 && (
                                        <Chip
                                            label={`+${extraTags}`}
                                            sx={{
                                                margin: '2px',
                                                backgroundColor: theme.palette.primary.main,
                                                color: '#fff',
                                                '& .MuiChip-deleteIcon': {
                                                    color: '#fff',
                                                },
                                            }}
                                        />
                                    )}
                                </>
                            );
                        }}
                    />

                    {/* <Autocomplete
                        multiple
                        size="small"
                        limitTags={2}
                        id="service-selection-demo"
                        // options={serviceTypes}
                        options={serviceTypes || []}
                        disabled={!selectedCategory}
                        value={selectedServiceIds}
                        onChange={handleSelectionChange}
                        getOptionLabel={(option) => option.service_type}
                        sx={{
                            borderRadius: '10px',
                            border: `2px solid ${theme.palette.primary.main}`,
                            '& .MuiSelect-select': {
                                padding: '8px',
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: 'none',
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: `2px solid ${theme.palette.primary.main}`
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: `2px solid ${theme.palette.primary.main}`
                            },
                        }}
                        renderOption={(props, option, { selected }) => {
                            return (
                                <>
                                    <div
                                        {...props}
                                        style={{
                                            backgroundColor: 'transparent',
                                            borderBottom: (serviceTypes.indexOf(option) < serviceTypes.length - 1) && `2px solid ${theme.palette.primary.main}`,
                                            '&:hover': { backgroundColor: 'transparent' },
                                            '&:focus': { backgroundColor: 'transparent' },
                                        }}
                                    >
                                        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', height: 27 }}>
                                            <Typography variant='body2' style={{ margin: 0, color: '#6F6F6F' }}>{option.service_type}</Typography>
                                            <Radio
                                                checked={selected}
                                                size='small'
                                                sx={{
                                                    padding: 0,
                                                    color: theme.palette.primary.main,
                                                    '&.Mui-checked': {
                                                        color: theme.palette.primary.main,
                                                    },
                                                }}
                                                onChange={() => {
                                                    if (selected) {
                                                        setSelectedServiceIds(selectedServiceIds.filter(service => service.id !== option.id));
                                                    } else {
                                                        setSelectedServiceIds([...selectedServiceIds, option]);
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </>
                            );
                        }}
                        style={{ minWidth: 205 }}
                        renderInput={(params) => (
                            <TextField
                                sx={{ color: '#6F6F6F' }}
                                {...params}
                                placeholder={selectedServiceIds.length >= 1 ? '' : t('Searchforservice')}
                            />
                        )}
                        renderTags={(service_types) => (
                            <Typography variant='body2' style={{ marginLeft: '8px', color: '#6F6F6F' }}>
                                {service_types.length > 1
                                    ? service_types.length === serviceTypes.length ? t('AllServices') : `${service_types.length} ${t('services')}`
                                    : `${service_types[0].service_type}`}
                            </Typography>
                        )}
                    /> */}
                </Box>

                {/* <Typography variant="h6" sx={{ mt: 1, ml: isSmallScreen ? 0 : 2, fontWeight: "500", color: '#1f1f1f', fontSize: '1.1rem' }} textAlign="left">
                    {t('Filterservices')}
                </Typography> */}

                {/* <Typography id="modal-modal-description" variant='subtitle1' sx={{ ml: isSmallScreen ? 0 : 2, fontWeight: "500", color: '#1f1f1f', fontSize: '0.92rem' }} textAlign="left">
                    {selectedCategory?.category || ''}
                </Typography> */}

                {/* <Box sx={{
                    padding: isSmallScreen ? 0 : 2,
                    paddingTop: 1,
                    // mt: isSmallScreen ? 2 : 0, 
                    display: 'flex', flexWrap: 'wrap', gap: 1.5, width: '100%'
                }}>
                    {(visibleServices.length == 0 || !selectedCategory) ?
                        (<Typography id="modal-modal-description" sx={{ ml: 0, color: '#1f1f1f', flex: 1, textAlign: 'center' }} textAlign="left">
                            No services found
                        </Typography>)
                        :
                        (visibleServices.map(item => (
                            <Paper
                                key={item.id}
                                sx={{
                                    cursor: 'pointer',
                                    padding: 0.5,
                                    borderRadius: 4,
                                    backgroundColor: selectedServiceIds.some(service => service.id === item.id) ? theme.palette.primary.main : 'white',
                                    flex: '1 1 auto',
                                    maxWidth: 'auto',
                                    height: 30,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    border: `1px solid ${theme.palette.primary.main}`,
                                    color: selectedServiceIds.some(service => service.id === item.id) ? 'white' : '#A79C92'
                                }}
                                elevation={0}
                                onClick={() => handleServiceClick(item)}
                            >
                                <Typography variant="body2" textAlign="center" sx={{ color: selectedServiceIds.some(service => service.id === item.id) ? 'white' : '#A79C92', fontSize: '0.80rem' }}>{item.service_type}</Typography>
                            </Paper>
                        )))
                    }
                    {hiddenCount > 0 && (
                        <Paper
                            sx={{
                                cursor: 'pointer',
                                padding: 0.3,
                                borderRadius: 4,
                                backgroundColor: 'white',
                                // flex: '1 1 auto',
                                // maxWidth: '150px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                border: `1px solid ${theme.palette.primary.main}`,
                                width: '50px'
                            }}
                            elevation={0}
                            onClick={() => { }}
                        >
                            <Typography variant="body2" textAlign="center" sx={{ color: '#A79C92', fontSize: '0.80rem' }}>+{hiddenCount}</Typography>
                        </Paper>
                    )}
                </Box> */}

                <Typography id="modal-modal-description" variant='body2' sx={{ mt: { xs: 2, md: -0.5 }, ml: isSmallScreen ? 0 : 2, mt: 2, fontWeight: "500", color: '#1f1f1f', fontSize: '1rem' }} textAlign="left">
                    {t('Price')}
                </Typography>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: isSmallScreen ? 'column' : 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <Grid
                        container
                        spacing={0}
                        marginLeft={isSmallScreen ? 0 : 0}
                        sx={{ width: isSmallScreen ? '100%' : '70%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                    >
                        <Grid item xs={5.5} sx={{ paddingRight: isSmallScreen ? 1 : 0 }}>
                            <Typography variant="body2" sx={{ marginBottom: 0.3, marginLeft: isSmallScreen ? null : 2, color: '#1f1f1f' }}>
                                {t('From')}
                            </Typography>
                            <TextField
                                variant="outlined"
                                size="small"
                                InputProps={{
                                    sx: {
                                        height: 35,
                                        fontSize: '0.85rem',
                                        borderRadius: '12px',
                                        width: isSmallScreen ? '100%' : '89%',
                                        marginLeft: isSmallScreen ? null : 2,
                                        border: `1px solid ${theme.palette.primary.main}`,
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            border: 'none',
                                        },
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: `1px solid ${theme.palette.primary.main}`
                                        },
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            borderColor: `1px solid ${theme.palette.primary.main}`
                                        },
                                    },
                                }}
                                placeholder="0"
                                value={fromValue}
                                onChange={(event) => handleNumericInput(event, setFromValue)}
                            />
                        </Grid>

                        <Grid item xs={1} sx={{ mt: 3 }}>
                            <Typography variant="body2" textAlign={'center'} sx={{ fontSize: '13px', fontWeight: 700, color: '#1f1f1f' }}>-</Typography>
                        </Grid>

                        <Grid item xs={5.5} sx={{ paddingLeft: isSmallScreen ? 1 : 0 }}>
                            <Typography variant="body2" sx={{ marginBottom: 0.3, color: '#1f1f1f' }}>
                                {t('To')}
                            </Typography>
                            <TextField
                                variant="outlined"
                                size="small"
                                InputProps={{
                                    sx: {
                                        borderRadius: '12px',
                                        height: 35,
                                        fontSize: '0.85rem',
                                        width: isSmallScreen ? '100%' : '89%',
                                        border: `1px solid ${theme.palette.primary.main}`,
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            border: 'none',
                                        },
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: `1px solid ${theme.palette.primary.main}`
                                        },
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            borderColor: `1px solid ${theme.palette.primary.main}`
                                        },
                                    },
                                }}
                                placeholder="50.000+"
                                value={toValue}
                                onChange={(event) => handleNumericInput(event, setToValue)}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={isSmallScreen ? 0 : 2} marginLeft={isSmallScreen ? 0 : 0}
                        sx={{
                            display: 'flex',
                            width: isSmallScreen ? '100%' : '40%',
                            // mt: isSmallScreen ? 2 : 0
                        }}>
                        <Grid item xs={12}>
                            <Typography variant="body2"
                                sx={{ marginBottom: 0.3, mt: isSmallScreen ? 2 : null, color: '#1f1f1f1' }}>
                                {t('Within')}
                            </Typography>
                            <FormControl fullWidth>
                                <Select
                                    id="demo-simple-select"
                                    size='small'
                                    // value={kilometer}
                                    value={kilometer}
                                    onChange={handleChange}
                                    sx={{
                                        height: 35,
                                        fontSize: '0.85rem',
                                        borderRadius: '12px',
                                        border: `1px solid ${theme.palette.primary.main}`,
                                        '& .MuiSelect-select': {
                                            padding: '8px',
                                        },
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            border: 'none',
                                        },
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: `1px solid ${theme.palette.primary.main}`
                                        },
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            borderColor: `1px solid ${theme.palette.primary.main}`
                                        },
                                    }}
                                    label={t('SelectDistance')}
                                    displayEmpty
                                >
                                    {/* <MenuItem value={''} sx={{ fontSize: '0.85rem' }}>{t('SelectDistance')}</MenuItem> */}
                                    <MenuItem value={'2'} sx={{ fontSize: '0.85rem' }}>2 km</MenuItem>
                                    <MenuItem value={'5'} sx={{ fontSize: '0.85rem' }}>5 km</MenuItem>
                                    <MenuItem value={'10'} sx={{ fontSize: '0.85rem' }}>10 km</MenuItem>
                                    <MenuItem value={'20'} sx={{ fontSize: '0.85rem' }}>20 km</MenuItem>
                                    <MenuItem value={'50'} sx={{ fontSize: '0.85rem' }}>50 km</MenuItem>
                                    <MenuItem value={'100'} sx={{ fontSize: '0.85rem' }}>100 km</MenuItem>
                                    <MenuItem value={'500'} sx={{ fontSize: '0.85rem' }}>Hele Danmark</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>

                <Typography
                    id="modal-modal-description"
                    variant='body1'
                    sx={{ mt: 2, ml: isSmallScreen ? 0 : 2, fontWeight: "500", color: '#1f1f1f', fontSize: '1rem' }}
                    textAlign="left"
                >
                    {t('searchtype')}
                </Typography>

                <Box
                    sx={{
                        padding: isSmallScreen ? 0 : 2,
                        mt: isSmallScreen ? 2 : 0,
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 1.5,
                        width: '100%',
                        justifyContent: 'space-between',
                        height: 50,
                        alignContent: 'center'
                    }}
                >
                    {cosmeticsData.slice(0, 3).map(item => (
                        <Paper
                            key={item.id}
                            sx={{
                                cursor: 'pointer',
                                padding: 0.2,
                                borderRadius: '12px',
                                height: 35,
                                backgroundColor: selectedFirstRow === item.name ? theme.palette.primary.main : 'white',
                                color: selectedFirstRow === item.name ? 'white' : 'black',
                                flex: '1 1 calc(33.33% - 10px)',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                border: `1px solid ${theme.palette.primary.main}`,
                                color: selectedFirstRow === item.name ? 'white' : '#A79C92'
                            }}
                            elevation={0}
                            onClick={() => handleFirstRowClick(item.name)}
                        >
                            <Typography variant='caption' textAlign="center" sx={{ color: selectedFirstRow === item.name ? 'white' : '#A79C92', lineHeight: 1.2 }}>
                                {/* {item.name} */}
                                {t(item.name)}
                            </Typography>
                        </Paper>
                    ))}
                </Box>

                <Box
                    sx={{
                        padding: isSmallScreen ? 0 : 2,
                        mt: isSmallScreen ? 2 : 0,
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 1.5,
                        width: '100%',
                        height: 50,
                        justifyContent: 'space-between',
                        alignContent: 'center'
                    }}
                >
                    {cosmeticsData.slice(3, 6).map(item => (
                        <Paper
                            key={item.id}
                            sx={{
                                cursor: 'pointer',
                                padding: 0.2,
                                borderRadius: '12px',
                                height: 35,
                                backgroundColor: selectedSecondRow === item.name ? theme.palette.primary.main : 'white',
                                color: selectedSecondRow === item.name ? 'white' : 'black',
                                flex: '1 1 calc(33.33% - 10px)',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                border: `1px solid ${theme.palette.primary.main}`,
                                color: selectedSecondRow === item.name ? 'white' : '#A79C92'
                            }}
                            elevation={0}
                            onClick={() => handleSecondRowClick(item.name)}
                        >
                            <Typography variant={'caption'} textAlign="center" sx={{ color: selectedSecondRow === item.name ? 'white' : '#A79C92', lineHeight: 1.2 }}>
                                {/* {item.name} */}
                                {t(item.name)}
                            </Typography>
                        </Paper>
                    ))}
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                    <Button
                        variant="contained"
                        sx={{
                            height: 45,
                            width: { xs: '100%', md: '80%' },
                            borderRadius: '12px',
                            color: 'white',
                            textTransform: 'none',
                            fontSize: '15px',
                            fontWeight: '500'
                        }}
                        disableElevation
                        onClick={() => {

                            console.log('selectedFirstRow', selectedFirstRow);
                            console.log('selectedSecondRow', selectedSecondRow);

                            search({
                                kilometer,
                                selectedServiceIds,
                                category_ids: selectedCategory,
                                selectedSecondRow,
                                fromValue,
                                toValue,
                                selectedFirstRow
                            })
                            // setOpen(false), setbuttonPressed(true), setExtendedSearch(true), setTabLoading(true), getStores() 

                        }}>
                        {t('showClinics')}
                    </Button>
                </Box>
            </Box>
        </Modal>
    )
}

export default AdvanceFilter
