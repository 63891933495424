import { Box, Chip,  IconButton, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';

function Header({storeDetails, from }) {
    const navigate = useNavigate();
    const avg_rating = storeDetails?.avg_rating;
    const total_reviews = storeDetails?.total_reviews;


    return (
        <>
        
            <Box
                sx={{
                    width: '100%',
                    height: { xs: 'calc(100vw * 9 / 16)', sm: 'calc(100vw * 9 / 16)', md: '250px' },
                    //backgroundImage: `url(${img})`,
                    backgroundImage: `url(https://fiind-dev.s3.amazonaws.com/${storeDetails?.image})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <IconButton
                    sx={{
                        position: 'absolute',
                        top: '20px',
                        left: '20px',
                        color: 'white',
                        zIndex: 10,
                    }}
                    // onClick={() =>  {(from == 'marketPlace') ? navigate('/') : navigate(-1) }}
                    onClick={() =>  {navigate('/')}}
                >
                    <ArrowBackIosIcon />
                </IconButton>
                <Chip
                    sx={{
                        position: 'absolute',
                        zIndex: 10,
                        bottom: { xs: '-16px', md: '-16px' },
                        textAlign: 'center',
                        backgroundColor: 'primary.main',
                        color: 'white',
                        padding: '16px',
                        fontWeight: 'bold',
                        height: { xs: '25px', md: '35px' },
                        borderRadius: '20px',
                        fontSize: {
                            xs: '12px',
                            sm: '14px',
                            md: '16px',
                            lg: '18px',
                        },
                    }}
                    variant='h1'
                    icon={<StarRoundedIcon color='primary.icon'/>}
                    label={<Typography variant='subtitle1' sx={{ color:'white'}}> {avg_rating ? avg_rating.toFixed(1) : ''} ({total_reviews || 0} {total_reviews !== 1 ? t('Reviews') : t('Review')})</Typography>}
                ></Chip>
            </Box>
            <Box sx={{
                  width: '100%',
                  height: { xs: 'calc(100vw * 9 / 16)', sm: 'calc(100vw * 9 / 16)', md: '250px' },
                  backgroundColor:'#000000',
                  position:'absolute',
                  top:0,
                  opacity: '30%'
                  
            }}/>

            </>
    )
}

export default Header
